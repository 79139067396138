let data = null
async function get() {
//    console.log('get', data);
    return data || await load();
}
async function load() {
    const response = await fetch('/tag.json');
    data = await response.json();
//    console.log('loaded', data);
    return data;
}

export default { get };